@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url('http://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=latin,latin-ext');  
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css');  


body{
  font-family: 'Open Sans', sans-serif;
  background:#fff;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Open Sans';
  margin: 0;
  padding: 0;
  color: #333;
  letter-spacing: 1px;
  text-transform:uppercase;
}

h1{
  font-size: 24px;
  line-height: 34px;
  color:#CF4647;
}

a{
  color: inherit;
}

.container{
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
}

.header{
  background-color: #f5f5f5;
  color: #999999;
  .contact{
    margin-bottom:0;
    li{
      border-right: 1px solid #E2E6E7;
      padding:15px 20px;
      line-height:20px;
    }
    &:first-child{
        border-left: 1px solid #E2E6E7;
    }
  }
}

.main-header{
  padding: 20px 0;
  .nav{
    font-size: 18px;
    margin-top:0px;
    margin-left:0px;
    color: #337ab7;
    .active{
      a{
        background:none;
        color: #CF4647;
        &:hover,
        &:focus{
          background: none;
          color: #CF4647;
        }
      }

    }
    a{
      &:hover{
        background: none;
        color: #CF4647;
      }
    }
  }
}

.slider {
  position: relative;  
  overflow: auto; 
  ul{
    padding-left:0;
    height: 450px!important;
    li { 
      float: left; 
      list-style: none;
      .description{
        position: relative;
        left: 45px;
        bottom: 70px;
        float:left;
        color: #fff;
        padding:5px 10px;
        display: inline-block;
        font-size: 15px;
        background: rgb(0,0,0); /* Fall-back for browsers that don't**/
        background: rgba(0,0,0, .5);
      }
    }
  }
  
  .dot {
    -webkit-filter: drop-shadow(0 1px 2px rgba(0,0,0,.3));
    -moz-filter: drop-shadow(0 1px 2px rgba(0,0,0,.3));
    -ms-filter: drop-shadow(0 1px 2px rgba(0,0,0,.3));
    -o-filter: drop-shadow(0 1px 2px rgba(0,0,0,.3));
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.3));
  }
  .dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 4px;
      
      text-indent: -999em;
      
      border: 2px solid #fff;
      border-radius: 6px;
      
      cursor: pointer;
      opacity: .4;
      
      -webkit-transition: background .5s, opacity .5s;
      -moz-transition: background .5s, opacity .5s;
      transition: background .5s, opacity .5s;
    }
    .active {
        background: #fff;
        opacity: 1;
      }
  }
}

.title{
  background:#f5f5f5;
  padding:40px 20px;
}

.desc{
  margin-top:20px; 
  font-size:16px;
  p{
    line-height:24px;
    text-align: justify;
  }
  h2{
    color:#524656;
    font-size:22px;
    margin:20px 0 10px 0;
  }
  h3{
    font-size:18px;
    margin:10px 0;
  }
  .col-md-8,
  .col-md-12{
    padding-left:30px;
  }

  ul{
    list-style:none;
  }
  .ref{
    padding-left:10px;
  } 
}

.cont{
  margin-top:20px;
}

.red{
  color:#CF4647;
}

.footer{
  background: #73767A;
  color:#fff;
  padding:20px 0;
  margin-top:20px;
}
.nas{
  margin-top: 20px;
  p{
    padding:20px 40px;
    text-align: justify;
    height: 215px;
  }
}
.panel-default > .panel-heading{
  background-color:#fff;
}
.panel-default{
  border-color:#fff;
}

.panel-title [aria-expanded="true"]{
  color:#CF4647;
}
h2.title{
  padding:0;
  background:none;
  line-height:1;
  margin-left: 12px;
}


